<template>
  <div>
    <div class="flex items-baseline justify-between">
      <UiHeader
        v-if="props.header"
        :header="props.header"
        header-layout="section-header"
      />
      <UiButton
        v-if="props.button?.link?.href && props.button?.text"
        :to="props.button.link.href"
        variant="link"
        :uppercase="false"
        icon-right="arrow"
        class="hidden lg:inline-flex"
      >
        {{ props.button?.text }}
      </UiButton>
    </div>
    <Swiper
      v-bind="swiperSettings"
      @init="onInit"
    >
      <SwiperSlide
        v-for="(value, key) in logotypes"
        :key="key"
        class="!flex items-center"
        @mouseenter="setIcon(key, key)"
        @mouseleave="setIcon(key, `${key}_white`)"
      >
        <UiIcon
          :name="value"
          lazy-img
          class="h-28 w-full object-contain"
        />
      </SwiperSlide>
    </Swiper>

    <UiButton
      v-if="props.button?.link?.href && props.button?.text"
      :to="props.button.link.href"
      variant="link"
      :uppercase="false"
      icon-right="arrow"
      class="mt-6 lg:hidden"
    >
      {{ props.button?.text }}
    </UiButton>
  </div>
</template>

<script lang="ts" setup>
import UiIcon from '@ui/components/UiIcon/UiIcon.vue'
import UiHeader from '@ui/components/UiHeader/UiHeader.vue'
import UiButton from '@ui/components/UiButton/UiButton.vue'

import { computed, ref } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Grid } from 'swiper/modules'
import type { SwiperOptions } from 'swiper/types/swiper-options'
import type { Logotypes } from './T3CeRawlplug_logotypes.types'
import 'swiper/css'
import 'swiper/css/grid'

const props = defineProps<Logotypes>()

const logotypes = ref({
  baumit: 'baumit_white',
  bolix: 'bolix_white',
  brico: 'brico_white',
  budimex: 'budimex_white',
  caparol: 'caparol_white',
  castorama: 'castorama_white',
  cemix: 'cemix_white',
  ceresit: 'ceresit_white',
  fatra: 'fatra_white',
  firestone: 'firestone_white',
  holcim: 'holcim_white',
  isover: 'isover_white',
  knauf: 'knauf_white',
  leroy: 'leroy_white',
  mapei: 'mapei_white',
  mota_engil: 'mota_engil_white',
  obi: 'obi_white',
  ppg: 'ppg_white',
  prb: 'prb_white',
  pri: 'pri_white',
  protan: 'protan_white',
  psb: 'psb_white',
  rockwool: 'rockwool_white',
  selena: 'selena_white',
  sig: 'sig_white',
  sika: 'sika_white',
  sopro: 'sopro_white',
  tim: 'tim_white',
  warbud: 'warbud_white',
})

function setIcon(name: string, value: string) {
  logotypes.value[name] = value
}

const swiperSettings = computed((): SwiperOptions => {
  return {
    modules: [Autoplay, Grid],
    spaceBetween: 15,
    slidesPerView: 2.5,
    slidesOffsetAfter: 32,
    loopAddBlankSlides: true,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    breakpoints: {
      480: {
        slidesPerView: 3,
        spaceBetween: 24,
      },
      640: {
        slidesPerView: 4,
      },
      768: {
        slidesPerView: 5,
      },
      1024: {
        slidesPerView: 5,
        spaceBetween: 32,
        grid: {
          rows: 2,
          fill: 'row',
        },
      },
    },
  }
})

const swiper = ref(null)
function onInit(swiperInstance: any) {
  swiper.value = swiperInstance
}

defineExpose({
  swiper,
})

onBeforeUnmount(() => {
  if (swiper.value) {
    swiper.value?.destroy?.()
  }
})
</script>
